#act-body {
  background-image: url("actBack.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: rgba(255, 255, 255, 0.3);
  background-blend-mode: lighten;
  padding-bottom: 70px;
}

#act-text {
  background-color: rgba(255, 255, 255, 0.5);
  width: 80vw;
  margin-left: 10vw;
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 30px;
  border-radius: 100px;
}

.act-heading {
  text-align: center;
  font-size: 90px;
}

.act-p {
  text-align: center;
  font-size: 30px;
  width: 40vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}

#act-events {
  width: 70%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

#act-events th {
  width: 50%;
  text-align: center;
  font-size: 50px;
  padding-bottom: 30px;
}

#act-events td {
  vertical-align: top;
}
