.admin-result-card{
  background-color:rgba(174, 198, 207, 0.2);
  padding: 1% 0 1% 5%;
  border-radius:5px;
  width:85%;
  margin:3% 5% 3% 5%;
}
.button{
  border-radius:10px;
  display:inline-block;
  color:white;
  font-weight:bold;
  font-family:inherit;
  border: none;
  text-decoration: none;
  padding: 1% 5% 1% 5%;
  margin: 1% 1% 1% 1%;
}
.button#success{
  background-color:#5cb85c;
}
.button#deny{
  background-color:#d9534f
}

.reported-user{
  background-color:rgba(255,189,185,0.3);
  padding: 1% 0 1% 5%;
  border-radius:5px;
  width:85%;
  margin:3% 10% 3% 5%;
  display:block;
}

.hznLine {
  width:90%;
  border:2px solid rgba(255,189,185,0.9);
  border-radius:5px;
}

.exteriorAdmin {
  display:flex;
  justify-content:space-between;
}

.resultsAdmin {
  display:inline-block;
  width:50%;
  margin-left:0%;
}

.reportsAdmin {
  display:inline-block;
  width:50%;
}
