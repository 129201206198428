.teamBody {
  font-family: 'Quicksand', sans-serif;
  background-color: #e3dfff;
  padding-bottom: 10%;
}

#heading {
  text-align: center;
  margin-top: 2%;
  font-size:80px;
  margin-bottom:1%;
}

#team-table {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

#team-table th, #team-table td {
  padding: 17px;
  text-align: center;
  width: 25%;
}

.profile-img {
  width: 200px;
  height: 200px;
}

.site-link {
  text-decoration: none;
  font-weight: bold;
  color: #98AFC7;
  padding: 5px;
  border-radius: 10px;
  font-size:40px;
}
.site-link:hover{
  color:#BCC6CC;
}
.homeBody{
  background-color:white;
}

.homeBody h1, h2,h3{
  display:inline-block;
}
.title{
  margin-left:30%;
  margin-bottom:0%;
}
.homeBody h2 {
  font-weight: normal;
  font-size:55px;
}
.homeBody h3 {
  opacity:0;
}

.homeBody td {
  vertical-align:top;
  padding:10px;
}

.homeBody th {
  font-size:22px;
  font-weight:500;

}
