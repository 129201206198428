@import url(https://fonts.googleapis.com/css?family=Montserrat|Quicksand|Syncopate&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", */
  /* "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", */
  /* sans-serif; */
  font-family:'Quicksand',Normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.top-header{
  background-color:#9a98b5;
  width:100%;
  height:3vh;
  overflow:hidden;
  color:#fff;
}
.bottom-header{
  background-color:white;
  height:6vh;
  overflow:hidden;
  border-bottom: 2px solid #eee;
}
.menu-button{
  display:inline-block;
  float:right;
  padding: 0.65% 1.1%;
  width: 9%;
  height:100%;
  font-size:18px;
  text-align:center;
  color:black;
  text-decoration: none;
}
.menu-button:hover{
  background-color:#ABA9A8;
}
.active{
  background-color:#D4D4D4;
}
.top{
  display:flex;
  justify-content:space-between;
  padding-left:1.5%;
}
#favicon-holder{
  display:inline-block;
  margin-left:2%;
}
#login-button{
  margin:0px;
  padding:2px;
  height:100%;
  width:6%;
  float:right;
  text-align:center;
  color:#fff;
}
#verify{
  text-align:center;
}
#verify-button{
  margin:0px;
  padding:2px;
  height:100%;
  text-align:center;
  background-color: #9a98b5;
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-family: 'Quicksand', sans-serif;
  font-style:italic;
  border:none;
}
#verify-button:hover{
  text-decoration:underline;
}
#login-button:hover{
  background-color:#ccc;
}
#login-button a{
  color:inherit;
  text-decoration:inherit;
}
#favicon{
  height:40px;
}


.dropdown{
  display:inline-block;
}
.dropbtn{
  background-color:#9a98b5;
  border:none;
  color:rgba(0, 0, 0, 0);
  font-family: 'Syncopate', sans-serif;
  font-weight:bold;
  vertical-align:4px;
  background-image: url(/static/media/arrowdown.af4f7600.svg);
  background-position:center;
  background-repeat: no-repeat, repeat;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: #ddd;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:hover .dropbtn {
  opacity:0.7;
  border-radius:2px;
  color:rgba(0, 0, 0, 0);
}
.bannedMessage{
  background-color:#d9534f;
  height:100%;
}

#homeBody {
  font-family: 'Quicksand', sans-serif;
  background-color: white;
  padding-bottom: 10%;
}

#title {
  display: block;
  width: 40%;
  margin-left:auto;
  margin-right:auto;
  padding-top: 12%;
  padding-bottom:2%;
}

#intro {
  height: 90.711vh;
  background-color: #e3dfff;
  background-image: url(/static/media/homepage4.511a30d1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-content: center;
}

#buttons {
  text-align: center;
}

.link {
  display: inline-block;
  color: white;
  margin: 3%;
  width: 12%;
  padding: 1.01% 1.01% 1.01% 1.01%;
  font-size: 28px;
  border-style:solid;
  border-color:white;
  text-decoration: none;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.17);
  border-radius: 15px;
}

.link:hover {
  color:#645464;
  background-color:white;
}

#about {
  margin-top: 100px;
  overflow: hidden;
}

#about-text {
  display: block;
  padding: 0px 20px 30px 20px;
  margin: auto;
  border-radius: 70px;
  text-align: center;
}


#project-text {
  display: block;
  padding: 0px 20px 30px 20px;
  margin: auto;
  border-radius: 70px;
  text-align: center;
}

.section-text {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 0%;
  font-size: 200%;
  font-weight: 350;
  letter-spacing: 0.2px;
  line-height: 50pt;
}

.heading {
  text-align: center;
  font-size: 85px;
  margin-bottom: 0%;
  margin-top: 0%;
  color: red(0,0,0,20);
  font-style:bold;
  padding-bottom:0;
}

.underline {
  border: 6px solid #875EB5;
  width:20%;
  margin-left:40%;
  margin-top:0;
  padding-top:0;
  border-radius:5px;
}

.small-link {
  color: black;
  font-size: 30px;
  text-decoration: none;
  font-weight: bold;
  margin: 5px 5px 5px 5px;
  margin-left: 39%;
  margin-right: 39%;
  background-color: #875EB5;
  padding: 10px 10px 10px 10px;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 9%;
}

.small-link a {
  display: block;
  color: white;
  text-decoration: none;
  margin: 0 auto;
}

.small-link a:hover {
  color: #ECDDFF;
}

.glow {
  font-size: 215px;
  color: #fff;
  margin: auto;
  width: 50%;
  padding-top: 8%;
  padding-bottom:2%;
  text-shadow: 0 0 3px #fff, 0 0 2px #fff, 0 0 15px #9a98b5, 0 0 25px #9a98b5, 0 0 35px #9a98b5, 0 0 50px #9a98b5, 0 0 70px #9a98b5;
  text-align: center;
}

#map-body{
  height:100%;
  width:100vw;
  overflow: hidden;
}
#left {
  scroll-behavior:smooth;
  display:inline-block;
  overflow-y:scroll;
  width: 28%;
  height:100%;
  background-color: #e3d8f1;
}


.result-active{
  font-size:1em;
  margin-left:5%;
  margin-right:5%;
  border-radius:5px;
  padding: 1% 0% 1% 5%;
  background-color:#fff;
}
.result-active:hover{
  background-color:#eee;
}
.result{
  background-color:#fff;
  margin:2% 5% 2% 5%;
  border-radius:5px;
  padding: .1% 0% .1% 5%;
  font-size:0.75em;
}
.result:hover{
  background-color:#eee;
}
.newResourceForm{
  margin:0% 5% 0% 5%;
  background-color:#EDE9F3;
  padding:10px;
  border-radius:10px;
}
.newResourceForm h2{
  text-align:center;
  background-color:#EDE9F3;
  border-radius:10px;
}
.fullContent{
  display:inline-block;
  width:80%;
}

.active-card-links{
  width:20%;
  align-content:right;
  display:inline-block;
}
.cardButton{
  font-size:4em;
  color:inherit;
  text-decoration: inherit;
}
.cardButton:hover{
  color:#fff;
}
.results-header{
  /* background-color:green; */
  height:10vh;
}
.results-title{
  height:100%;
  display:inline-block;
  padding-left:3%;
  padding-bottom:none;
  margin-bottom:none;
  font-size:56px;
  /* background-color:blue; */
}
.add-resource{
  display:inline-block;
  margin-top:2%;
  margin-right:5%;
  /* background-color:red; */
  height:7vh;
  width:7vh;
  float:right;
}
.add-resource svg{
  fill:#000;
  -webkit-transition: fill 1s;
  transition: fill 1s;
}
.add-resource svg g{
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transition: transform .5s;
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
}
.add-resource[box-open=t] svg g{
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.add-resource svg g #svg_4{
  opacity:0;
  fill:#c4c3d0;
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
}
.add-resource:hover svg g #svg_4{
  opacity:1.0;
}
.results-sub-header{
  padding-left:5%;
}
#places-wrapper {
  width:20%;
}
#places {
  padding: 10px 10px;
  box-shadow: 0 0 0 2pt #675E80;
}
#places:focus {
  outline: 0;
}
input[type=text] {
  padding: 10px 10px;
  border: 2px solid #675E80;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
}
input[type=text]:focus {
  outline: 0;
}
#search-bar {
  width: 85%;
  margin-left: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}
#search-submit {
  width: 10%;
  margin-right: 3%;
  margin-top: 4px;
  padding: 5px 5px 5px 5px;
  background-color:transparent;
  color: #675E80;
  border-radius: 10px;
  float: right;
  border:none;

}
#search-glass {
  width: 80%;
}
#search-glass:hover{
  width:90%;
}
.address {
  width: 45%;
}
input[type=number] {
  padding: 10px 10px;
  border: 2px solid #675E80;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
}
input[type=number]:focus {
  outline: 0;
}
#submit-button {
  background-color: #675E80;
  border: none;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom:10px;
  border-radius:10px;
}
#submit-button:hover {
  opacity:0.7;
}
#submit {
  text-align: center;
}

#resource-map {
  height:100%;
  width: 72%;
  display:inline-block;
  float:right;
  z-index:1;
}
#body{
  background-color:#ccc;
  height:91vh;
}

#filter-wrapper {
  position: fixed;
  bottom: 0;
  margin-left: 28%;
  width: 100%;
  z-index:4;
}

#filter-wrapper button{
  z-index:3;
}
#filter-form {
  background-color: #EDE9F3;
  padding: 10px 10px 0px 10px;
  border: 0px;
  margin-top:25px;
  bottom:0;
  position: fixed;
  height:19%;
  color:#222;
}

#form-table {
  width: 100%;
  text-align:left;
}
#form-table td {
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  font-weight: bold;
}
#map-stars {
  margin-left: auto;
  margin-right: auto;
}

#checkbox-table {
  margin-bottom: 20px;
}
input[type=checkbox] {
  border-radius: 3px;
  box-shadow: 0 0 0 1.5pt #904c77;
}
.newResourceForm {
  margin-bottom: 30px;
}

.verify-alert{
  color:red;
  padding:2px;
  border:solid red 2px;
  text-align:center;
}

.open-button {
  background-color: white;
  border: none;
  cursor: pointer;
  position: fixed;
  bottom: -5px;
  margin-bottom:0%;
  margin-left:35%;
  width: 5%;
  font-size:18px;
  font-weight:bolder;
  color: rgba(0, 0, 0, 0);
  background-image: url(/static/media/arrowup.b8d6ab38.svg);
  background-position:center;
	background-repeat: no-repeat, repeat;
  border: 2px solid #aaa;
  border-bottom:none;
}

.open-button:hover{
}
.select-css {
	display: block;
	font-size: 12px;
	line-height: 1.05;
	width: 100%;
	max-width: 100%;
	margin: 0;
	border: none;
	border-radius: .5em;
	-webkit-appearance: none;
	background-color: #fff;
	background-image: url(/static/media/arrow.c6879187.svg);
  background-position:center;
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
.select-css::-ms-expand {
	display: none;
}
#desc-box{
  width:100%;
  height:13vh;
  padding: 10px 10px;
  border: 2px solid #675E80;
  box-sizing: border-box;
  border-radius: 10px;
}

.cardtitle{
  margin-bottom:0;
  padding-bottom:0;
}

.star-holder{
}
img.star{
  height:12%;
  width:12%;
}
img.star-small{
  height:7%;
  width:7%;
}

.resourcesBody {
  font-family: 'Quicksand', sans-serif;
  padding-bottom: 3%;
}

.topRow{
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  margin-top:5.3%;
}

.firstRow{
  width: 40%;
  text-align:center;
  display:flex;
  justify-content:space-around;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
#title {
  display: block;
  width: 40%;
  margin-left:auto;
  margin-right:auto;
  padding-top: 12%;
  padding-bottom:2%;
}
.secondRow {
  /* background-color:white; */
  display:flex;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  text-align:center;
  margin-top: 3%;
}
.bottomRow{
  margin-left: auto;
  margin-right: auto;
  width: 150px;
}
.resourcesLink {
  text-align: center;
  color: white;
  letter-spacing: 1.5px;
  width: 9.4%;
  padding: 0.3% 0.3% 0.3% 0.3%;
  font-size: 25px;
  border-style:solid;
  border-color:white;
  text-decoration: none;
  font-weight: bold;
  background-color: rgba(88, 50, 115,0.75);
  border-radius: 10px;
  display: inline-block;

}

.topRowLink{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.link:hover {
  color:#645464;
  background-color:white;
}

.heading {
  text-align: center;
  margin-bottom: 20px;
}
.circle-button svg{
  /* background-color:#333; */
  fill:#999;
  -webkit-transition: fill 1s;
  transition: fill 1s;
}
.circle-button:hover svg{
  fill:#000;
}
.circle-button p{
  color:#999;
  -webkit-transition:fill 1s;
  transition: fill 1s;
}
.circle-button:hover p{
  color:#000;
}
.circle-button{
  text-align:center;
  height:135px;
  width:135px;
}
.center-title{
  padding-top:5%;
}
.center-text{
  text-align:center;
  display:inline-block;
  /* background-color:#eee; */
  width:55%;
}


#support-sites, #hotline-sites, #donate-sites {
  display: flex;
  flex-wrap: wrap;
}
#donate-top {
  width: 70%;
  font-size: 30px;
  text-align: center;
  margin: auto;
  margin-bottom: 30px;
}

.resultpage-wrapper{
  padding-bottom: 20px;
}
.location-card{
  background-color: #eee;
  margin:3% 25% 3% 25%;
  padding:3% 7% 3% 7%;
  border-radius:20px;
}
.location-info h1{
  margin-left: 0;
}
.reviews{

}
.review{
  background-color:#fff;
  margin: 10% 0% 10% 0%;
  border-radius:20px;
  padding: 2% 0% 2% 10%;
}
.review-user{
  /* background-color:blue; */
  display:inline-block;
  font-weight:bolder;
}
.review-link {
  color: black;
  text-decoration: none;
}
.review-link:hover {
  text-decoration: underline;
}
.review-time{
  font-size:0.75em;
}
.review-rating{
  margin-left:40%;
}
.review-content{
  border-left-color: #000;
  border-left-width: 20px;
  width: 90%;
}
.add-review-button-holder{
  text-align:center;
}
.add-review-button{
  color:inherit;
  text-decoration:inherit;
  font-size:4em;
}
.add-review-button:hover{
  color:#888;
}


.add-review-form{

}
.review-area{
  width:100%;
  height:150px;
}


.verify-alert{
  color:red;
  padding:2px;
  border:solid red 2px;
  text-align:center;
  margin:5% 3% 5% 3%;
}

.helpful-bar{
  /* background-color:#efefef; */
  padding-bottom: 20px;
  padding-right: 40px;
}
.helpful-text{
  font-size:1rem;
  padding:0;
  margin:0;
  display:inline-block;
}
.thumb{
  border-radius:5px;
  float:right;
  height:1.5rem;
  width:1.2rem;
  margin:0;
  padding:0px 3% 0px 3%;
  display:inline-block;
  /* background-color:green; */
}
.thumb:hover{
  background-color:#efefef;
}
.thumb#down svg{
  -webkit-transform-origin:center;
          transform-origin:center;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.title-header{
  height:30px;
  /* background-color:green; */
}
.title-header svg{
  display:inline-block;
  height:25px;
  margin-right:5%;
  /* background-color:red; */
  float:right;
  border-radius:5px;
}
.title-header svg:hover{
  background-color:gray;
}

.review-area {
  border: 2px solid black;
  border-radius: 15px;
  padding: 10px 10px;
  outline: none;
}

#submit-review {
  background-color: #eee;
  text-align: center;
  margin-top: 10px;
  padding: 10px 12px;
  border: 2px solid black;
  border-radius: 10px;
  outline: none;
}

#submit-review:hover {
  background-color: black;
  color: white;
}

#site-body {
  background-color: #e3d8f1;
  padding: 10px;
  margin-left: 2vw;
  margin-bottom: 40px;
  width: 28vw;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

#site-title {
  font-size: 30px;
  color: black;
  font-weight: bold;
  text-decoration: none;
}

#site-title:hover {
  text-decoration: underline;
}

#site-num {
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
  font-weight: bold;
  background-color: #bd93f4;
  outline: none;
  display: inline-block;
  color:white;
}

#site-logo {
  background-color: black;
}

#site-hours {
  font-weight: bold;
  font-size: 20px;
}

#site-desc {
  font-weight: bold;
  padding:15px;
}

#site-date {
  font-weight: bold;
  font-size: 20px;
}

#act-body {
  background-image: url(/static/media/actBack.9497625b.jpg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: rgba(255, 255, 255, 0.3);
  background-blend-mode: lighten;
  padding-bottom: 70px;
}

#act-text {
  background-color: rgba(255, 255, 255, 0.5);
  width: 80vw;
  margin-left: 10vw;
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 30px;
  border-radius: 100px;
}

.act-heading {
  text-align: center;
  font-size: 90px;
}

.act-p {
  text-align: center;
  font-size: 30px;
  width: 40vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}

#act-events {
  width: 70%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

#act-events th {
  width: 50%;
  text-align: center;
  font-size: 50px;
  padding-bottom: 30px;
}

#act-events td {
  vertical-align: top;
}

#faq-title {
  text-align: center;
  font-size: 80px;
  margin-bottom: 10px;
}
#title-page {
  height: 100vh;
}

#top-buttons {
  width: 90vw;
  margin-left: 5vw;
  margin-top: 20px;
}
#bottom-buttons {
  width: 60vw;
  margin-left: 20vw;
  margin-top: 20px;
}
#top-buttons td{
  width: 30vw;
  text-align: center;
  vertical-align: top;
}
#bottom-buttons td {
  width: 30vw;
  text-align: center;
  vertical-align: top;
}

.button-table a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.big-link {
  font-size: 30px;
  text-align: center;
}

.small-links {
  display: inline-block;
}

.small-links a {
  display: inline-block;
  font-size: 20px;
  margin-top: 10px;
}

#faq-body h2 {
  margin-left: 40px;
}

.question_header button{
  text-decoration:none;
  color:#777;
  font-size:42px;
  margin:none;
  border:none;
  background-color:rgba(255,255,255,0);
  font-family:"Quicksand", sans-serif;
}

.question_header button:hover{
  color:#333;
}

.question_header {
  width:100%;
  border-collapse:collapse;
}

.question_header td{
  border-bottom:2.5px solid #ccc;
  border-top:2.5px solid #ccc;
  padding-left:3%;
  padding-right:3%;
  padding-top:2%;
  padding-bottom:2%;
}

.answercard h2{
  font-size:30px !important;
  margin-bottom:0;
  padding-bottom:5px;
  border-bottom: 3px solid #BD93F4;
  margin-left:0;
  padding-left:0 !important;
}

.answercard h3{
  padding-left:3%;
  padding-right:3%;
  font-weight:normal !important;
}

.answerimage {
  width:40%;
  margin-left:30%;
  border-bottom:15px solid #fe661c;
  padding-bottom:0;
  margin-bottom:0;
}

.answerimage2{
  width:50%;
  margin-left:25%;
}

.center {
  display:block;
  text-align:center;
}

#ed-title {
  text-align: center;
  font-size:90px;
  font-weight:bolder;
  margin-bottom:0;
  padding-bottom:0;
  color:#444;
}
#ed-categories {
  width: 65vw;
  margin-left: 17.5vw;
  text-align: center;
  table-layout: fixed;
}

#ed-categories .divider {
  width:20%;
  border-right:1.5px solid #aaa;
}

#ed-categories a {
  display: inline-block;
  text-decoration: none;
  color: #555;
  font-size: 30px;
  text-align: center;
  font-size:18px;
  font-weight:600;
}

#ed-categories a:hover{
  color:black;
}

#ed-categories ul{
  list-style:circle;
  text-align:left;
  font-size:13px;
  text-decoration:none;
  vertical-align:top;
}

.small_links {
  font-size:13px !important;
  font-weight:normal !important;
}

#lastone {
  border-right:none !important;
}

.educationBody hr{
  border: 3px solid #875EB5;
  width:8%;
  margin-left:46%;
  margin-top:10px;
  border-radius:5px;
  -webkit-transition: width 2s, margin-left 2s;
  transition: width 2s, margin-left 2s;
}

.educationBody hr:hover{
  width:12%;
  margin-left:44%;
}

.educationBody h1{
  text-align:center;
}

.educationBody{
  background-color:rgb(230,221,237);
}

.card {
  padding:3%;
  background-color:white;
  margin-left:10%;
  margin-right:10%;
  margin-top:3%;
  margin-bottom:3%;
  border-radius:15px;
}

.teamBody {
  font-family: 'Quicksand', sans-serif;
  background-color: #e3dfff;
  padding-bottom: 10%;
}

#heading {
  text-align: center;
  margin-top: 2%;
  font-size:80px;
  margin-bottom:1%;
}

#team-table {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

#team-table th, #team-table td {
  padding: 17px;
  text-align: center;
  width: 25%;
}

.profile-img {
  width: 200px;
  height: 200px;
}

.site-link {
  text-decoration: none;
  font-weight: bold;
  color: #98AFC7;
  padding: 5px;
  border-radius: 10px;
  font-size:40px;
}
.site-link:hover{
  color:#BCC6CC;
}
.homeBody{
  background-color:white;
}

.homeBody h1, h2,h3{
  display:inline-block;
}
.title{
  margin-left:30%;
  margin-bottom:0%;
}
.homeBody h2 {
  font-weight: normal;
  font-size:55px;
}
.homeBody h3 {
  opacity:0;
}

.homeBody td {
  vertical-align:top;
  padding:10px;
}

.homeBody th {
  font-size:22px;
  font-weight:500;

}

body {
  color:#444;
}
hr{
  width:75%;
  float:left;
  margin-left:2%;
  border: 2px solid #ddd;
  border-radius:2px;
}
h1{
  font-size:50px;
  margin-left:2%;
  margin-bottom:0;
  margin-top:1%;
}
.profile form {
  margin-left:2%;
  width:25%;
}
.sidenav {
  width: 20%;
  height:50vh;
  display:inline-block;
  z-index: 1;
  top: 20px;
  left: 10px;
  background: #eee;
  overflow-x: hidden;
}
.sidenav a {
  padding: 18px 8px 18px 20px;
  text-decoration: none;
  font-size: 17px;
  color:#444;
  display: block;
}
.sidenav a:hover {
  font-weight:bold;
}
.selected{
  background-color: #aaa;
  font-weight:bold;
  color:#fff !important;
}
.page{
  width:100%;
  display:flex;
  justify-content: flex-start;
  margin-top:2%;
  margin-left: 2%;
}
.main{
  margin-left:5%;
  width:50%
}
.edit{
  width:50%
}
.submit{
  width:50%;
}
.field{
  font-weight:bolder;
  color:#4B3880;
  font-size:18px;
}
.main form{
  font-weight:bold;
  color:#4B3880;
}
.main input[type=text] {
  padding: 10px 10px;
  border: 2px solid #675E80;
  box-sizing: border-box;
  width: 75%;
}
.main input[type=email] {
  padding: 10px 10px;
  border: 2px solid #675E80;
  box-sizing: border-box;
  width: 100%;
  border-radius:10px;
}
.main input[type=password] {
  padding: 10px 10px;
  border: 2px solid #675E80;
  box-sizing: border-box;
  width: 100%;
  border-radius:10px;
}
.main input[type=number] {
  padding: 10px 10px;
  border: 2px solid #675E80;
  box-sizing: border-box;
  width: 75%;
}
.login {
  text-decoration: none;
  color: #444;
  background-color:white;
  border:3px solid #444;
  padding:1%;
  width: 9%;
  display:flex;
  margin-left:auto;
  margin-right:auto;
  justify-content: center;
  font-weight:bold;
  margin-top:2%;
  border-radius:20px;
}

.login:hover{
  background-color:#444;
  color:white;
}
.main table{
  width:100%;
}
.main td{
  vertical-align: text-top;
}
.choice{
  color:#333;
}
.main a{
  text-decoration:none;
  color: #444;
  background-color:white;
  border:2px solid #444;
  padding-top:1.5%;
  padding-bottom:1.5%;
  padding-left:5%;
  padding-right:5%;
  font-weight:bold;
  margin-top:2%;
  border-radius:10px;
  margin-left:auto;
  margin-right:auto;
  width:10%;
  display:flex;
  justify-content: center;
}

.main a:hover{
  background-color:#444;
  color:white;
}

.main button{
  text-decoration:none;
  color: #444;
  font-size:16px;
  background-color:white;
  border:2px solid #444;
  padding-top:1.5%;
  padding-bottom:1.5%;
  padding-left:10%;
  padding-right:10%;
  font-weight:bold;
  margin-top:2%;
  border-radius:10px;
  margin-left:auto;
  margin-right:auto;
  width:10%;
  display:flex;
  justify-content: center;

}

.main button:hover{
  background-color:#444;
  color:white;
}

.main h3{
  color:red;
  padding:2px;
  border:solid red 2px;
}

.location-card table{
  margin-bottom:0px;
}

.location-card h3 {
  display:inline-block;
}

.location-card button{
  float:right;
  background-color: #FA5858;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-weight:bold;
  border-radius:5px;
}

.location-card button:hover{
  background-color:#DF0101;
}

.location-card table{
  width:100%;
}
.reportForm {
  width: 30%;
  height:50%;
  margin:auto;
  border: 1.5px solid #ccc;
  padding: 15px;
  box-shadow: 5px 5px #aaa;
  border-radius:10px;
  position:absolute;
  top:25%;;
  left:35%;
  z-index:5;
  background-color:white;
  line-height:30px;
}

.reportForm h2 {
  display:inline-block;
}

.reportForm button {
  float:right;
  border-radius:12px;
  border:1px solid #aaa;
  background-color:white;
}


.reportForm form input[type='submit']{
  border:none;
  padding:8px;
  font-size:14px;
  font-family:'Quicksand';
  text-align:center;
  background-color:#aaa;
  color:white;
  font-weight:bold;
  width:30%;
  position:absolute;
  left:35%;
  right:35%;
}


.reportForm form input[type='submit']:hover{
  background-color: #bbb;
}

.admin-result-card{
  background-color:rgba(174, 198, 207, 0.2);
  padding: 1% 0 1% 5%;
  border-radius:5px;
  width:85%;
  margin:3% 5% 3% 5%;
}
.button{
  border-radius:10px;
  display:inline-block;
  color:white;
  font-weight:bold;
  font-family:inherit;
  border: none;
  text-decoration: none;
  padding: 1% 5% 1% 5%;
  margin: 1% 1% 1% 1%;
}
.button#success{
  background-color:#5cb85c;
}
.button#deny{
  background-color:#d9534f
}

.reported-user{
  background-color:rgba(255,189,185,0.3);
  padding: 1% 0 1% 5%;
  border-radius:5px;
  width:85%;
  margin:3% 10% 3% 5%;
  display:block;
}

.hznLine {
  width:90%;
  border:2px solid rgba(255,189,185,0.9);
  border-radius:5px;
}

.exteriorAdmin {
  display:flex;
  justify-content:space-between;
}

.resultsAdmin {
  display:inline-block;
  width:50%;
  margin-left:0%;
}

.reportsAdmin {
  display:inline-block;
  width:50%;
}

