#faq-title {
  text-align: center;
  font-size: 80px;
  margin-bottom: 10px;
}
#title-page {
  height: 100vh;
}

#top-buttons {
  width: 90vw;
  margin-left: 5vw;
  margin-top: 20px;
}
#bottom-buttons {
  width: 60vw;
  margin-left: 20vw;
  margin-top: 20px;
}
#top-buttons td{
  width: 30vw;
  text-align: center;
  vertical-align: top;
}
#bottom-buttons td {
  width: 30vw;
  text-align: center;
  vertical-align: top;
}

.button-table a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.big-link {
  font-size: 30px;
  text-align: center;
}

.small-links {
  display: inline-block;
}

.small-links a {
  display: inline-block;
  font-size: 20px;
  margin-top: 10px;
}

#faq-body h2 {
  margin-left: 40px;
}

.question_header button{
  text-decoration:none;
  color:#777;
  font-size:42px;
  margin:none;
  border:none;
  background-color:rgba(255,255,255,0);
  font-family:"Quicksand", sans-serif;
}

.question_header button:hover{
  color:#333;
}

.question_header {
  width:100%;
  border-collapse:collapse;
}

.question_header td{
  border-bottom:2.5px solid #ccc;
  border-top:2.5px solid #ccc;
  padding-left:3%;
  padding-right:3%;
  padding-top:2%;
  padding-bottom:2%;
}

.answercard h2{
  font-size:30px !important;
  margin-bottom:0;
  padding-bottom:5px;
  border-bottom: 3px solid #BD93F4;
  margin-left:0;
  padding-left:0 !important;
}

.answercard h3{
  padding-left:3%;
  padding-right:3%;
  font-weight:normal !important;
}

.answerimage {
  width:40%;
  margin-left:30%;
  border-bottom:15px solid #fe661c;
  padding-bottom:0;
  margin-bottom:0;
}

.answerimage2{
  width:50%;
  margin-left:25%;
}

.center {
  display:block;
  text-align:center;
}
