.resourcesBody {
  font-family: 'Quicksand', sans-serif;
  padding-bottom: 3%;
}

.topRow{
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  margin-top:5.3%;
}

.firstRow{
  width: 40%;
  text-align:center;
  display:flex;
  justify-content:space-around;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
#title {
  display: block;
  width: 40%;
  margin-left:auto;
  margin-right:auto;
  padding-top: 12%;
  padding-bottom:2%;
}
.secondRow {
  /* background-color:white; */
  display:flex;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  text-align:center;
  margin-top: 3%;
}
.bottomRow{
  margin-left: auto;
  margin-right: auto;
  width: 150px;
}
.resourcesLink {
  text-align: center;
  color: white;
  letter-spacing: 1.5px;
  width: 9.4%;
  padding: 0.3% 0.3% 0.3% 0.3%;
  font-size: 25px;
  border-style:solid;
  border-color:white;
  text-decoration: none;
  font-weight: bold;
  background-color: rgba(88, 50, 115,0.75);
  border-radius: 10px;
  display: inline-block;

}

.topRowLink{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.link:hover {
  color:#645464;
  background-color:white;
}

.heading {
  text-align: center;
  margin-bottom: 20px;
}
.circle-button svg{
  /* background-color:#333; */
  fill:#999;
  -webkit-transition: fill 1s;
  transition: fill 1s;
}
.circle-button:hover svg{
  fill:#000;
}
.circle-button p{
  color:#999;
  -webkit-transition:fill 1s;
  transition: fill 1s;
}
.circle-button:hover p{
  color:#000;
}
.circle-button{
  text-align:center;
  height:135px;
  width:135px;
}
.center-title{
  padding-top:5%;
}
.center-text{
  text-align:center;
  display:inline-block;
  /* background-color:#eee; */
  width:55%;
}


#support-sites, #hotline-sites, #donate-sites {
  display: flex;
  flex-wrap: wrap;
}
#donate-top {
  width: 70%;
  font-size: 30px;
  text-align: center;
  margin: auto;
  margin-bottom: 30px;
}
