#site-body {
  background-color: #e3d8f1;
  padding: 10px;
  margin-left: 2vw;
  margin-bottom: 40px;
  width: 28vw;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

#site-title {
  font-size: 30px;
  color: black;
  font-weight: bold;
  text-decoration: none;
}

#site-title:hover {
  text-decoration: underline;
}

#site-num {
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
  font-weight: bold;
  background-color: #bd93f4;
  outline: none;
  display: inline-block;
  color:white;
}

#site-logo {
  background-color: black;
}

#site-hours {
  font-weight: bold;
  font-size: 20px;
}

#site-desc {
  font-weight: bold;
  padding:15px;
}

#site-date {
  font-weight: bold;
  font-size: 20px;
}
