.review-area {
  border: 2px solid black;
  border-radius: 15px;
  padding: 10px 10px;
  outline: none;
}

#submit-review {
  background-color: #eee;
  text-align: center;
  margin-top: 10px;
  padding: 10px 12px;
  border: 2px solid black;
  border-radius: 10px;
  outline: none;
}

#submit-review:hover {
  background-color: black;
  color: white;
}
