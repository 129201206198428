body {
  color:#444;
}
hr{
  width:75%;
  float:left;
  margin-left:2%;
  border: 2px solid #ddd;
  border-radius:2px;
}
h1{
  font-size:50px;
  margin-left:2%;
  margin-bottom:0;
  margin-top:1%;
}
.profile form {
  margin-left:2%;
  width:25%;
}
.sidenav {
  width: 20%;
  height:50vh;
  display:inline-block;
  z-index: 1;
  top: 20px;
  left: 10px;
  background: #eee;
  overflow-x: hidden;
}
.sidenav a {
  padding: 18px 8px 18px 20px;
  text-decoration: none;
  font-size: 17px;
  color:#444;
  display: block;
}
.sidenav a:hover {
  font-weight:bold;
}
.selected{
  background-color: #aaa;
  font-weight:bold;
  color:#fff !important;
}
.page{
  width:100%;
  display:flex;
  justify-content: flex-start;
  margin-top:2%;
  margin-left: 2%;
}
.main{
  margin-left:5%;
  width:50%
}
.edit{
  width:50%
}
.submit{
  width:50%;
}
.field{
  font-weight:bolder;
  color:#4B3880;
  font-size:18px;
}
.main form{
  font-weight:bold;
  color:#4B3880;
}
.main input[type=text] {
  padding: 10px 10px;
  border: 2px solid #675E80;
  box-sizing: border-box;
  width: 75%;
}
.main input[type=email] {
  padding: 10px 10px;
  border: 2px solid #675E80;
  box-sizing: border-box;
  width: 100%;
  border-radius:10px;
}
.main input[type=password] {
  padding: 10px 10px;
  border: 2px solid #675E80;
  box-sizing: border-box;
  width: 100%;
  border-radius:10px;
}
.main input[type=number] {
  padding: 10px 10px;
  border: 2px solid #675E80;
  box-sizing: border-box;
  width: 75%;
}
.login {
  text-decoration: none;
  color: #444;
  background-color:white;
  border:3px solid #444;
  padding:1%;
  width: 9%;
  display:flex;
  margin-left:auto;
  margin-right:auto;
  justify-content: center;
  font-weight:bold;
  margin-top:2%;
  border-radius:20px;
}

.login:hover{
  background-color:#444;
  color:white;
}
.main table{
  width:100%;
}
.main td{
  vertical-align: text-top;
}
.choice{
  color:#333;
}
.main a{
  text-decoration:none;
  color: #444;
  background-color:white;
  border:2px solid #444;
  padding-top:1.5%;
  padding-bottom:1.5%;
  padding-left:5%;
  padding-right:5%;
  font-weight:bold;
  margin-top:2%;
  border-radius:10px;
  margin-left:auto;
  margin-right:auto;
  width:10%;
  display:flex;
  justify-content: center;
}

.main a:hover{
  background-color:#444;
  color:white;
}

.main button{
  text-decoration:none;
  color: #444;
  font-size:16px;
  background-color:white;
  border:2px solid #444;
  padding-top:1.5%;
  padding-bottom:1.5%;
  padding-left:10%;
  padding-right:10%;
  font-weight:bold;
  margin-top:2%;
  border-radius:10px;
  margin-left:auto;
  margin-right:auto;
  width:10%;
  display:flex;
  justify-content: center;

}

.main button:hover{
  background-color:#444;
  color:white;
}

.main h3{
  color:red;
  padding:2px;
  border:solid red 2px;
}

.location-card table{
  margin-bottom:0px;
}

.location-card h3 {
  display:inline-block;
}

.location-card button{
  float:right;
  background-color: #FA5858;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-weight:bold;
  border-radius:5px;
}

.location-card button:hover{
  background-color:#DF0101;
}

.location-card table{
  width:100%;
}
.reportForm {
  width: 30%;
  height:50%;
  margin:auto;
  border: 1.5px solid #ccc;
  padding: 15px;
  box-shadow: 5px 5px #aaa;
  border-radius:10px;
  position:absolute;
  top:25%;;
  left:35%;
  z-index:5;
  background-color:white;
  line-height:30px;
}

.reportForm h2 {
  display:inline-block;
}

.reportForm button {
  float:right;
  border-radius:12px;
  border:1px solid #aaa;
  background-color:white;
}


.reportForm form input[type='submit']{
  border:none;
  padding:8px;
  font-size:14px;
  font-family:'Quicksand';
  text-align:center;
  background-color:#aaa;
  color:white;
  font-weight:bold;
  width:30%;
  position:absolute;
  left:35%;
  right:35%;
}


.reportForm form input[type='submit']:hover{
  background-color: #bbb;
}
