.top-header{
  background-color:#9a98b5;
  width:100%;
  height:3vh;
  overflow:hidden;
  color:#fff;
}
.bottom-header{
  background-color:white;
  height:6vh;
  overflow:hidden;
  border-bottom: 2px solid #eee;
}
.menu-button{
  display:inline-block;
  float:right;
  padding: 0.65% 1.1%;
  width: 9%;
  height:100%;
  font-size:18px;
  text-align:center;
  color:black;
  text-decoration: none;
}
.menu-button:hover{
  background-color:#ABA9A8;
}
.active{
  background-color:#D4D4D4;
}
.top{
  display:flex;
  justify-content:space-between;
  padding-left:1.5%;
}
#favicon-holder{
  display:inline-block;
  margin-left:2%;
}
#login-button{
  margin:0px;
  padding:2px;
  height:100%;
  width:6%;
  float:right;
  text-align:center;
  color:#fff;
}
#verify{
  text-align:center;
}
#verify-button{
  margin:0px;
  padding:2px;
  height:100%;
  text-align:center;
  background-color: #9a98b5;
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-family: 'Quicksand', sans-serif;
  font-style:italic;
  border:none;
}
#verify-button:hover{
  text-decoration:underline;
}
#login-button:hover{
  background-color:#ccc;
}
#login-button a{
  color:inherit;
  text-decoration:inherit;
}
#favicon{
  height:40px;
}


.dropdown{
  display:inline-block;
}
.dropbtn{
  background-color:#9a98b5;
  border:none;
  color:rgba(0, 0, 0, 0);
  font-family: 'Syncopate', sans-serif;
  font-weight:bold;
  vertical-align:4px;
  background-image: url('./arrowdown.svg');
  background-position:center;
  background-repeat: no-repeat, repeat;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: #ddd;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:hover .dropbtn {
  opacity:0.7;
  border-radius:2px;
  color:rgba(0, 0, 0, 0);
}
.bannedMessage{
  background-color:#d9534f;
  height:100%;
}
