#map-body{
  height:100%;
  width:100vw;
  overflow: hidden;
}
#left {
  scroll-behavior:smooth;
  display:inline-block;
  overflow-y:scroll;
  width: 28%;
  height:100%;
  background-color: #e3d8f1;
}


.result-active{
  font-size:1em;
  margin-left:5%;
  margin-right:5%;
  border-radius:5px;
  padding: 1% 0% 1% 5%;
  background-color:#fff;
}
.result-active:hover{
  background-color:#eee;
}
.result{
  background-color:#fff;
  margin:2% 5% 2% 5%;
  border-radius:5px;
  padding: .1% 0% .1% 5%;
  font-size:0.75em;
}
.result:hover{
  background-color:#eee;
}
.newResourceForm{
  margin:0% 5% 0% 5%;
  background-color:#EDE9F3;
  padding:10px;
  border-radius:10px;
}
.newResourceForm h2{
  text-align:center;
  background-color:#EDE9F3;
  border-radius:10px;
}
.fullContent{
  display:inline-block;
  width:80%;
}

.active-card-links{
  width:20%;
  align-content:right;
  display:inline-block;
}
.cardButton{
  font-size:4em;
  color:inherit;
  text-decoration: inherit;
}
.cardButton:hover{
  color:#fff;
}
.results-header{
  /* background-color:green; */
  height:10vh;
}
.results-title{
  height:100%;
  display:inline-block;
  padding-left:3%;
  padding-bottom:none;
  margin-bottom:none;
  font-size:56px;
  /* background-color:blue; */
}
.add-resource{
  display:inline-block;
  margin-top:2%;
  margin-right:5%;
  /* background-color:red; */
  height:7vh;
  width:7vh;
  float:right;
}
.add-resource svg{
  fill:#000;
  -webkit-transition: fill 1s;
  transition: fill 1s;
}
.add-resource svg g{
  transform-origin: center;
  -webkit-transition: transform .5s;
  transition: transform .5s;
}
.add-resource[box-open=t] svg g{
  transform: rotate(45deg);
}
.add-resource svg g #svg_4{
  opacity:0;
  fill:#c4c3d0;
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
}
.add-resource:hover svg g #svg_4{
  opacity:1.0;
}
.results-sub-header{
  padding-left:5%;
}
#places-wrapper {
  width:20%;
}
#places {
  padding: 10px 10px;
  box-shadow: 0 0 0 2pt #675E80;
}
#places:focus {
  outline: 0;
}
input[type=text] {
  padding: 10px 10px;
  border: 2px solid #675E80;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
}
input[type=text]:focus {
  outline: 0;
}
#search-bar {
  width: 85%;
  margin-left: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}
#search-submit {
  width: 10%;
  margin-right: 3%;
  margin-top: 4px;
  padding: 5px 5px 5px 5px;
  background-color:transparent;
  color: #675E80;
  border-radius: 10px;
  float: right;
  border:none;

}
#search-glass {
  width: 80%;
}
#search-glass:hover{
  width:90%;
}
.address {
  width: 45%;
}
input[type=number] {
  padding: 10px 10px;
  border: 2px solid #675E80;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
}
input[type=number]:focus {
  outline: 0;
}
#submit-button {
  background-color: #675E80;
  border: none;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom:10px;
  border-radius:10px;
}
#submit-button:hover {
  opacity:0.7;
}
#submit {
  text-align: center;
}

#resource-map {
  height:100%;
  width: 72%;
  display:inline-block;
  float:right;
  z-index:1;
}
#body{
  background-color:#ccc;
  height:91vh;
}

#filter-wrapper {
  position: fixed;
  bottom: 0;
  margin-left: 28%;
  width: 100%;
  z-index:4;
}

#filter-wrapper button{
  z-index:3;
}
#filter-form {
  background-color: #EDE9F3;
  padding: 10px 10px 0px 10px;
  border: 0px;
  margin-top:25px;
  bottom:0;
  position: fixed;
  height:19%;
  color:#222;
}

#form-table {
  width: 100%;
  text-align:left;
}
#form-table td {
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  font-weight: bold;
}
#map-stars {
  margin-left: auto;
  margin-right: auto;
}

#checkbox-table {
  margin-bottom: 20px;
}
input[type=checkbox] {
  border-radius: 3px;
  box-shadow: 0 0 0 1.5pt #904c77;
}
.newResourceForm {
  margin-bottom: 30px;
}

.verify-alert{
  color:red;
  padding:2px;
  border:solid red 2px;
  text-align:center;
}

.open-button {
  background-color: white;
  border: none;
  cursor: pointer;
  position: fixed;
  bottom: -5px;
  margin-bottom:0%;
  margin-left:35%;
  width: 5%;
  font-size:18px;
  font-weight:bolder;
  color: rgba(0, 0, 0, 0);
  background-image: url('./arrowup.svg');
  background-position:center;
	background-repeat: no-repeat, repeat;
  border: 2px solid #aaa;
  border-bottom:none;
}

.open-button:hover{
}
.select-css {
	display: block;
	font-size: 12px;
	line-height: 1.05;
	width: 100%;
	max-width: 100%;
	margin: 0;
	border: none;
	border-radius: .5em;
	-webkit-appearance: none;
	background-color: #fff;
	background-image: url('./arrow.svg');
  background-position:center;
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
.select-css::-ms-expand {
	display: none;
}
#desc-box{
  width:100%;
  height:13vh;
  padding: 10px 10px;
  border: 2px solid #675E80;
  box-sizing: border-box;
  border-radius: 10px;
}

.cardtitle{
  margin-bottom:0;
  padding-bottom:0;
}
