#homeBody {
  font-family: 'Quicksand', sans-serif;
  background-color: white;
  padding-bottom: 10%;
}

#title {
  display: block;
  width: 40%;
  margin-left:auto;
  margin-right:auto;
  padding-top: 12%;
  padding-bottom:2%;
}

#intro {
  height: 90.711vh;
  background-color: #e3dfff;
  background-image: url("./homepage4.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-content: center;
}

#buttons {
  text-align: center;
}

.link {
  display: inline-block;
  color: white;
  margin: 3%;
  width: 12%;
  padding: 1.01% 1.01% 1.01% 1.01%;
  font-size: 28px;
  border-style:solid;
  border-color:white;
  text-decoration: none;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.17);
  border-radius: 15px;
}

.link:hover {
  color:#645464;
  background-color:white;
}

#about {
  margin-top: 100px;
  overflow: hidden;
}

#about-text {
  display: block;
  padding: 0px 20px 30px 20px;
  margin: auto;
  border-radius: 70px;
  text-align: center;
}


#project-text {
  display: block;
  padding: 0px 20px 30px 20px;
  margin: auto;
  border-radius: 70px;
  text-align: center;
}

.section-text {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 0%;
  font-size: 200%;
  font-weight: 350;
  letter-spacing: 0.2px;
  line-height: 50pt;
}

.heading {
  text-align: center;
  font-size: 85px;
  margin-bottom: 0%;
  margin-top: 0%;
  color: red(0,0,0,20);
  font-style:bold;
  padding-bottom:0;
}

.underline {
  border: 6px solid #875EB5;
  width:20%;
  margin-left:40%;
  margin-top:0;
  padding-top:0;
  border-radius:5px;
}

.small-link {
  color: black;
  font-size: 30px;
  text-decoration: none;
  font-weight: bold;
  margin: 5px 5px 5px 5px;
  margin-left: 39%;
  margin-right: 39%;
  background-color: #875EB5;
  padding: 10px 10px 10px 10px;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 9%;
}

.small-link a {
  display: block;
  color: white;
  text-decoration: none;
  margin: 0 auto;
}

.small-link a:hover {
  color: #ECDDFF;
}

.glow {
  font-size: 215px;
  color: #fff;
  margin: auto;
  width: 50%;
  padding-top: 8%;
  padding-bottom:2%;
  text-shadow: 0 0 3px #fff, 0 0 2px #fff, 0 0 15px #9a98b5, 0 0 25px #9a98b5, 0 0 35px #9a98b5, 0 0 50px #9a98b5, 0 0 70px #9a98b5;
  text-align: center;
}
