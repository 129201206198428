.star-holder{
}
img.star{
  height:12%;
  width:12%;
}
img.star-small{
  height:7%;
  width:7%;
}
