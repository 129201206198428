.resultpage-wrapper{
  padding-bottom: 20px;
}
.location-card{
  background-color: #eee;
  margin:3% 25% 3% 25%;
  padding:3% 7% 3% 7%;
  border-radius:20px;
}
.location-info h1{
  margin-left: 0;
}
.reviews{

}
.review{
  background-color:#fff;
  margin: 10% 0% 10% 0%;
  border-radius:20px;
  padding: 2% 0% 2% 10%;
}
.review-user{
  /* background-color:blue; */
  display:inline-block;
  font-weight:bolder;
}
.review-link {
  color: black;
  text-decoration: none;
}
.review-link:hover {
  text-decoration: underline;
}
.review-time{
  font-size:0.75em;
}
.review-rating{
  margin-left:40%;
}
.review-content{
  border-left-color: #000;
  border-left-width: 20px;
  width: 90%;
}
.add-review-button-holder{
  text-align:center;
}
.add-review-button{
  color:inherit;
  text-decoration:inherit;
  font-size:4em;
}
.add-review-button:hover{
  color:#888;
}


.add-review-form{

}
.review-area{
  width:100%;
  height:150px;
}


.verify-alert{
  color:red;
  padding:2px;
  border:solid red 2px;
  text-align:center;
  margin:5% 3% 5% 3%;
}

.helpful-bar{
  /* background-color:#efefef; */
  padding-bottom: 20px;
  padding-right: 40px;
}
.helpful-text{
  font-size:1rem;
  padding:0;
  margin:0;
  display:inline-block;
}
.thumb{
  border-radius:5px;
  float:right;
  height:1.5rem;
  width:1.2rem;
  margin:0;
  padding:0px 3% 0px 3%;
  display:inline-block;
  /* background-color:green; */
}
.thumb:hover{
  background-color:#efefef;
}
.thumb#down svg{
  transform-origin:center;
  transform: rotate(180deg);
}

.title-header{
  height:30px;
  /* background-color:green; */
}
.title-header svg{
  display:inline-block;
  height:25px;
  margin-right:5%;
  /* background-color:red; */
  float:right;
  border-radius:5px;
}
.title-header svg:hover{
  background-color:gray;
}
