#ed-title {
  text-align: center;
  font-size:90px;
  font-weight:bolder;
  margin-bottom:0;
  padding-bottom:0;
  color:#444;
}
#ed-categories {
  width: 65vw;
  margin-left: 17.5vw;
  text-align: center;
  table-layout: fixed;
}

#ed-categories .divider {
  width:20%;
  border-right:1.5px solid #aaa;
}

#ed-categories a {
  display: inline-block;
  text-decoration: none;
  color: #555;
  font-size: 30px;
  text-align: center;
  font-size:18px;
  font-weight:600;
}

#ed-categories a:hover{
  color:black;
}

#ed-categories ul{
  list-style:circle;
  text-align:left;
  font-size:13px;
  text-decoration:none;
  vertical-align:top;
}

.small_links {
  font-size:13px !important;
  font-weight:normal !important;
}

#lastone {
  border-right:none !important;
}

.educationBody hr{
  border: 3px solid #875EB5;
  width:8%;
  margin-left:46%;
  margin-top:10px;
  border-radius:5px;
  transition: width 2s, margin-left 2s;
}

.educationBody hr:hover{
  width:12%;
  margin-left:44%;
}

.educationBody h1{
  text-align:center;
}

.educationBody{
  background-color:rgb(230,221,237);
}

.card {
  padding:3%;
  background-color:white;
  margin-left:10%;
  margin-right:10%;
  margin-top:3%;
  margin-bottom:3%;
  border-radius:15px;
}
